<template>
  <b-card
    class="mt-5 text-center"
    bg-variant="transparent"
    body-class="border-all"
  >
    <b-card-text
      class="h6"
    >
      No posts
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
} from 'bootstrap-vue';

export default {
  components: {
    BCard,
    BCardText,
  },
};
</script>

<style></style>
